import React, { useContext, useEffect } from 'react';
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { BannerStateContainer } from '@confluence/banners';
import { ErrorDisplay, ErrorBoundary, Attribution } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	ADMIN_ANNOUNCEMENT_BANNER_DISMISS_EXPERIENCE,
	ADMIN_ANNOUNCEMENT_BANNER_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import type { Appearance } from './AdminAnnouncementBannerComponent';
import { AdminAnnouncementBannerComponent } from './AdminAnnouncementBannerComponent';
import { useRegisterAdminAnnouncementBanner } from './useRegisterAdminAnnouncementBanner';
import { parseBannerContent } from './parseBannerContent';
import { getLinks } from './getLinks';
import { getEmojis } from './getEmojis';
import { shouldMarkErrorAsHandled } from './shouldMarkErrorAsHandled';
import { ADMIN_ANNOUNCEMENT_BANNER_METRIC } from './perf.config';
import { useIsPageEligibleForAdminAnnouncementBanner } from './useIsPageEligibleForAdminAnnouncementBanner';
import { migratorPromptWithEmptyContent } from './useMigratorPromptContent';

type AdminAnnouncementBannerProps = {
	bannerState: BannerStateContainer;
};

export const BannerComponent: React.FC<AdminAnnouncementBannerProps> = ({ bannerState }) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	useEffect(() => {
		experienceTracker.start({
			name: ADMIN_ANNOUNCEMENT_BANNER_EXPERIENCE,
		});
	}, [experienceTracker]);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { cloudId, userId } = useSessionData();
	const {
		shouldRender,
		hasOtherBanners,
		onDismiss,
		error: queryError,
		data,
		loading,
		shouldRenderMigratorPrompt,
	} = useRegisterAdminAnnouncementBanner(bannerState);

	const {
		id,
		appearance,
		isDismissible = false,
		content,
	} = shouldRenderMigratorPrompt
		? migratorPromptWithEmptyContent
		: data?.adminAnnouncementBanner || {};

	useEffect(() => {
		if (shouldRender) {
			trackBannerRenderEvent('rendered', id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldRender, id]);

	useEffect(() => {
		if (hasOtherBanners) {
			trackBannerRenderEvent('blocked');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasOtherBanners]);

	useEffect(() => {
		if (queryError) {
			trackBannerRenderEvent('failed');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryError]);

	const trackBannerRenderEvent = (status: 'failed' | 'blocked' | 'rendered', id?: string) => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'adminAnnouncementBanner',
					action: 'rendered',
					actionSubject: 'banner',
					attributes: {
						status,
						bannerId: status === 'rendered' ? id : undefined,
						linkCount: status === 'rendered' ? getLinks(parsedContent).length : undefined,
						bannerContainerLength:
							status === 'blocked' ? bannerState?.state?.banners?.length : undefined,
						blockedOn: status === 'blocked' ? bannerState?.state?.banners?.[0]?.name : undefined,
					},
				},
			}).fire();
		}
	};

	if (hasOtherBanners) {
		experienceTracker.abort({
			name: ADMIN_ANNOUNCEMENT_BANNER_EXPERIENCE,
			reason: 'Banner is blocked',
		});
		return null;
	}

	if (queryError) {
		experienceTracker.stopOnError({
			name: ADMIN_ANNOUNCEMENT_BANNER_EXPERIENCE,
			error: queryError,
		});
		if (shouldMarkErrorAsHandled(queryError)) {
			markErrorAsHandled(queryError);
			return null;
		}
		return <ErrorDisplay error={queryError} />;
	}
	if (shouldRender) {
		experienceTracker.succeed({
			name: ADMIN_ANNOUNCEMENT_BANNER_EXPERIENCE,
		});
	} else {
		/**
		 * No rendering of AAB banner is considered successful user experience for below scenarios:
		 * 1. Banner is dismissed
		 * 2. Banner does not exist
		 * 3. Banner is turned off
		 */
		!loading &&
			experienceTracker.succeed({
				name: ADMIN_ANNOUNCEMENT_BANNER_EXPERIENCE,
			});
		return null;
	}

	const parsedContent = parseBannerContent(content);

	const onBannerDismissed = () => {
		if (!isDismissible) return;
		experienceTracker.start({
			name: ADMIN_ANNOUNCEMENT_BANNER_DISMISS_EXPERIENCE,
		});
		try {
			onDismiss();
			experienceTracker.succeed({
				name: ADMIN_ANNOUNCEMENT_BANNER_DISMISS_EXPERIENCE,
			});
		} catch (error) {
			experienceTracker.stopOnError({
				name: ADMIN_ANNOUNCEMENT_BANNER_DISMISS_EXPERIENCE,
				error,
			});
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'adminAnnouncementBanner',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'adminAnnouncementBannerDismissButton',
				attributes: {
					bannerId: id,
					linkCount: getLinks(parsedContent).length,
					emojiCount: getEmojis(parsedContent).length,
					appearance,
					dismissable: isDismissible,
				},
			},
		}).fire();
	};

	const onClick = (e: any) => {
		e.preventDefault();
		const link = e.target.closest(`a`);

		if (!link) return;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'adminAnnouncementBanner',
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'adminAnnouncementBannerContentLink',
				// Helps track the migrator prompt banner.
				attributes: { bannerId: id },
			},
		}).fire();
		window.open(link.href, '_blank');
	};

	return (
		<ErrorBoundary attribution={Attribution.ADMIN_EXPERIENCE}>
			<>
				<PageSegmentLoadStart metric={ADMIN_ANNOUNCEMENT_BANNER_METRIC} />
				<AdminAnnouncementBannerComponent
					onClick={onClick}
					content={parsedContent}
					isDismissible={isDismissible}
					appearance={appearance as Appearance}
					userId={userId}
					cloudId={cloudId}
					onDismiss={onBannerDismissed}
					shouldRenderMigratorPrompt={shouldRenderMigratorPrompt}
				/>
			</>
		</ErrorBoundary>
	);
};

export const AdminAnnouncementBanner = () => {
	const isPageEligibleForAdminAnnouncementBanner = useIsPageEligibleForAdminAnnouncementBanner();
	if (!isPageEligibleForAdminAnnouncementBanner) {
		return null;
	}
	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				return <BannerComponent bannerState={bannerState} />;
			}}
		</Subscribe>
	);
};
